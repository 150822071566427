import React, { useCallback } from 'react';
import cx from 'classnames';

import { Tag as TagType } from '../../dto';

import styles from './Tag.module.scss';

type TagProps = TagType & {
    className?: string;
    selected?: boolean;
    onClick?: (id: number) => void;
};

const Tag: React.VFC<TagProps> = ({ id, name, className, selected, onClick }) => {
    const handleClick = useCallback(() => {
        onClick && onClick(id);
    }, [id, onClick]);

    return (
        <button
            className={ cx({
                [styles.tag]: true,
                [styles.selected]: selected,
            }, className) }
            onClick={handleClick}
        >
            {name}
        </button>
    );
};

export default Tag;