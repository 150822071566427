import { Tag, Post, HTTP_STATUS } from '../dto';

type ApiUrl = `/api/${string}`;
type Headers = Record<string, string>;
type Empty = {};
type AuthResponse = { token: string };
type FetchOptions = {
    method: string;
    body?: string;
    headers: Headers;
}

interface Api {
    tags(): Promise<Tag[]>;
    posts(): Promise<Post[]>;
    auth(login: string): Promise<Empty>;
    auth(login: string, code: string): Promise<AuthResponse>;
    removePost(id: number): Promise<Empty>;
    createLink(postId: number): Promise<{ token: string }>;
    postContent(token: string): Promise<Post>;
}

const defaultHeaders: Headers = {
    'content-type': 'application/json',
    'accept': 'application/json',
};

const API: Api = {
    tags: () => request<Tag[]>('/api/tags', 'GET', undefined, getAuthHeaders()),
    posts: () => request<Post[]>('/api/posts', 'GET', undefined, getAuthHeaders()),
    auth: (login: string, code?: string): Promise<any> => (
        code
            ? request<AuthResponse>('/api/auth', 'POST', { login, code })
            : request<Empty>('/api/auth', 'POST', { login })
    ),
    removePost: (id: number) => request<Empty>(`/api/post`, 'DELETE', { id }, getAuthHeaders()),
    createLink: (postId: number) => request<{ token: string }>('/api/link', 'POST', { id: postId }, getAuthHeaders()),
    postContent: (token: string) => request<Post>(`/api/link?token=${token}`, 'GET'),
};

export default API;

async function request<T>(url: ApiUrl, method: 'GET' | 'POST' | 'DELETE' = 'GET', params?: {}, headers?: Headers): Promise<T> {
    const options: FetchOptions = {
        method,
        headers: {
            ...defaultHeaders,
            ...headers,
        }
    };

    if (params) {
        options.body = JSON.stringify(params);
    }

    const response = await fetch(url, options);

    switch (response.status) {
        case HTTP_STATUS.OK:
            return response.json() as Promise<T>;

        case HTTP_STATUS.BAD_REQUEST:
            throw new RequestError();

        case HTTP_STATUS.UNAUTHORIZED:
            throw new UnauthorizedError();

        case HTTP_STATUS.INTERNAL_ERROR:
            throw new ResponseError();

        default:
            throw new UnknownError();
    }
}

function getAuthHeaders(): Headers {
    const token = localStorage.getItem('token');

    if (!token) {
        throw new UnauthorizedError();
    }

    return {
        authorization: `Bearer ${token}`,
    };
}

export class UnauthorizedError {}
export class RequestError {}
export class ResponseError {}
export class UnknownError {}