import React from 'react';
import cx from 'classnames';

import styles from './Button.module.scss';

type Props = {
    icon?: React.ReactNode
    onClick: () => void
    small?: boolean
    className?: string
    disabled?: boolean
}

const Button: React.FC<Props> = ({ icon, children, onClick, small, className, disabled }) => (
    <button className={ cx({
        [styles.button]: true,
        [styles.small]: small,
        [styles.withIcon]: Boolean(icon)
     }, className) } onClick={ onClick } disabled={ disabled }>
        { icon }
        <span className={ styles.text }>{ children }</span>
    </button>
);

export default Button;