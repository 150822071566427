import React from 'react';

import styles from './Popup.module.scss';

type Props = {
    title: string
    actions: React.ReactNode
}

const Popup: React.FC<Props> = ({ title, children, actions }) => (
    <>
        <div className={ styles.backdrop } />
        <div className={ styles.container }>
            <div className={ styles.window }>
                <strong className={ styles.header }>
                    { title }
                </strong>
                <div className={ styles.content }>
                    { children }
                </div>
                <div className={ styles.actions }>
                    { actions }
                </div>
            </div>
        </div>
    </>
);

export default Popup;