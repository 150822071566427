import React from 'react';

import styles from './Container.module.scss';

const Container: React.FC = ({ children }) => (
    <main className={ styles.container }>
        <section className={ styles.body }>
            { children }
        </section>
    </main>
);

export default Container;