import React, { useCallback, useState } from 'react';
import cx from 'classnames';

import Tag from '../Tag';
import * as Icons from '../Icons';
import { Post as PostType } from '../../dto';
import API from '../../util/api';

import styles from './Post.module.scss';

type PostProps = Omit<PostType, 'content'> & {
    children: string;
    noCopy?: boolean
    onRemove?: (id: number) => void;
};

const Post: React.VFC<PostProps> = ({ id, tags, timestamp, children, noCopy, onRemove }) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const handleRemove = useCallback(
        () => {
            setIsLoading(true);
            onRemove?.(id);
        },
        [ id, onRemove ]
    );
    const copyLink = useCallback(
        async () => {
            try {
                const { token } = await API.createLink(id);
                const url = `https://memo.akovalev.ru/?post=${token}`;

                try {
                    await copyToClipboard(url);
                    alert('Link copied!');
                } catch {
                    alert(`Unable to copy link to he clipboard:\r\n${url}`);
                }
            } catch {
                alert('Unable to generate link =(');
            }
        }, [ id ]
    );

    return (
        <div className={ cx(styles.post, {
            [styles.disabled]: isLoading,
        }) }>
            <div className={ styles.header }>
                <div className={ styles.tags }>
                    { tags.map(
                        tag => (<Tag key={ tag.id } { ...tag } className={ styles.tag } />)
                    ) }
                </div>
                { !noCopy && <button onClick={ copyLink } className={ styles.action }><Icons.Link /></button> }
                { onRemove && <button onClick={ handleRemove } className={ styles.action }><Icons.Cross /></button> }
            </div>
            <div className={ styles.content } dangerouslySetInnerHTML={{ __html: children }} />
            <div className={ styles.footer }>
                <div className={ styles.date }>{ formatDate(timestamp) }</div>
            </div>
        </div>
    );
}

export default Post;

function formatDate(seconds: number) {
    const date = new Date(seconds * 1000);
    const items = [
        date.getDate(),
        date.getMonth() + 1,
        date.getFullYear(),
        date.getHours(),
        date.getMinutes()
    ].map(number => number.toString().padStart(2, '0'));

    return items.slice(0, 3).join('.') + ' ' + items.slice(3).join(':');
}

function copyToClipboard(str: string): Promise<void> {
    return window.navigator?.clipboard?.writeText(str)
        ?? Promise.reject('The Clipboard API is not available.');
};