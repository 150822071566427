import React, { useState, useEffect, useCallback } from 'react';

import Chat from './components/Chat';
import Container from './components/Container';
import Processing from './components/Processing';
import SinglePost from './components/SinglePost';
import API, { UnauthorizedError, RequestError, ResponseError, UnknownError } from './util/api';
import { Tag, Post } from './dto';

type State = {
  type: 'loading'
} | {
  type: 'post'
  post: Post;
} | {
  type: 'chat'
  tags: Tag[];
  posts: Post[];
};

function App() {
  const [ state, setState ] = useState<State>({ type: 'loading' });

  useEffect(() => {
    const token = getQueryParam('post');
    const post = token
      ? API.postContent(token)
      : Promise.reject();

    post
      .then(post => setState({ type: 'post', post }))
      .catch(
        () => loadChatState().then(state => setState(state))
      );
  }, []);

  const refresh = () => {
    setState({ type: 'loading' });

    loadChatState().then(state => setState(state));
  };

  const handleRemovePost = useCallback(async (id: number) => {
    try {
      await API.removePost(id);

      setState(state => {
        if (state.type !== 'chat') {
          return state;
        }

        return {
          ...state,
          posts: state.posts.filter(
            post => post.id !== id
          ),
        };
      });
    } catch (e) {
      handleError(e);
    }
  }, []);

  switch (state.type) {
    case 'loading':
      return (
        <Container>
          <Processing>loading...</Processing>
        </Container>
      );

    case 'post':
      return (
        <Container>
          <SinglePost post={state.post} onClose={ refresh } />
        </Container>
      );

    case 'chat':
      return (
        <Container>
          <Chat
              tags={state.tags}
              posts={state.posts}
              onRemovePost={ handleRemovePost }
              onRefresh={ refresh }
          />
        </Container>
      );
  }
}

async function loadChatState(): Promise<State> {
  try {
    return await Promise.all([ API.tags(), API.posts() ]).then(
      ([ tags, posts ]) => {
        const usedTags = new Set(
          posts.flatMap(
            post => post.tags.map(
              tag => tag.id
            )
          )
        );

        return {
          type: 'chat',
          tags: tags.filter(
            tag => usedTags.has(tag.id)
          ),
          posts
        };
      }
    );
  } catch (e) {
    if (await handleError(e)) {
      return loadChatState();
    }

    throw e;
  }
};

async function handleError(error: any): Promise<boolean> {
  if (error instanceof UnauthorizedError) {
    return auth();
  }

  if (error instanceof RequestError) {
    return fail('Bad request!');
  }

  if (error instanceof ResponseError) {
    return fail('Internal server error!');
  }

  if (error instanceof UnknownError) {
    return fail('Unknown error!');
  }

  return fail('Unexpected error!');
}

function fail(message: string): false {
  alert(message);

  return false;
}

async function auth(): Promise<boolean> {
  const login = prompt('login');

  if (!login) {
    return false;
  }

  try {
    await API.auth(login);

    const code = prompt('auth code');

    if (!code) {
      return false;
    }

    const { token } = await API.auth(login, code);

    localStorage.setItem('token', token);

    return true;
  } catch (e) {
    return handleError(e);
  }
}

function getQueryParam(param: string): string|null {
  return new URLSearchParams(window.location.search).get(param);
}

export default App;
