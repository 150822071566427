import React, { useState, useCallback } from 'react';

import Action from '../Action';
import Left from '../Left';
import Post from '../Post';
import Processing from '../Processing';
import TagSelector from '../TagSelector';
import * as Icons from '../Icons';
import { Tag as TagType, Post as PostType } from '../../dto';

import styles from './Chat.module.scss';

type Props = {
    tags: TagType[];
    posts: PostType[];
    onRemovePost: (id: number) => Promise<void>;
    onRefresh: () => void;
}

type State = {
    type: 'idle' | 'selecting-tags',
    filter: number[]
}

const Chat: React.VFC<Props> = ({ tags, posts, onRemovePost, onRefresh }) => {
    const [ state, setState ] = useState<State>({
        type: 'idle',
        filter: [],
    });

    const handleFilterClick = useCallback(() => setState(
        oldState => ({ ...oldState, type: 'selecting-tags' })
    ), []);

    const filterSet = new Set<number>(state.filter);
    const filtered = filterSet.size
        ? posts.filter(
            post => post.tags.some(
                tag => filterSet.has(tag.id)
            )
        ) : posts;

    const content = filtered.length
        ? (
            <section className={ styles.container }>
                <Left>
                    { `${filtered.length} item(s)` }
                    <button onClick={ onRefresh } className={ styles.refresh }><Icons.Refresh /></button>
                </Left>

                <section className={ styles.list }>
                    {filtered.map(
                        ({ content, ...post }) => (
                            <Post key={post.id} { ...post } onRemove={ onRemovePost } >{ content }</Post>
                        )
                    )}
                </section>
            </section>
        )
        : (
            <Processing>
                Nothing to show
            </Processing>
        );

    return (
        <>
            { content }

            <Action onClick={ handleFilterClick }>
                <Icons.Filter />
            </Action>

            { state.type === 'selecting-tags' && <TagSelector
                tags={ tags }
                value={ state.filter }
                onClose={ filter => setState({ type: 'idle', filter }) }
            /> }
        </>
    );
};

export default Chat;
