import React from 'react';

import Left from '../Left';
import Post from '../Post';
import * as Icons from '../Icons';
import { Post as PostType } from '../../dto';

import styles from './SinglePost.module.scss';

type Props = {
    post: PostType;
    onClose: () => void;
}

const SinglePost: React.VFC<Props> = ({ post, onClose }) => (
    <section className={ styles.container }>
        <Left>
            { `#${post.id}` }
            <button onClick={ onClose } className={ styles.refresh }><Icons.Cross /></button>
        </Left>

        <section className={ styles.list }>
            <Post
                id={ post.id }
                tags={ post.tags }
                timestamp={ post.timestamp }
                noCopy
            >
                { post.content }
            </Post>
        </section>
    </section>
);

export default SinglePost;
