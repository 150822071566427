import React, { useState, useEffect } from 'react';

import Button from '../Button';
import Tag from '../Tag';
import Popup from '../Popup';
import { Tag as TagType } from '../../dto';

import styles from './TagSelector.module.scss';

type Props = {
    tags: TagType[]
    value: number[]
    onClose: (tags: number[]) => void
}

const TagSelector = ({ tags, value, onClose }: Props) => {
    const [ currentValue, setCurrentValue ] = useState<number[]>(value);

    useEffect(() => setCurrentValue(value), [ value ]);

    const toggle = (tag: number) => setCurrentValue(
        currentValue.includes(tag)
            ? currentValue.filter(
                id => id !== tag
            ) : currentValue.concat([ tag ])
    );

    return (
        <Popup
            title="Filter"
            actions={(
                <section className={ styles.actions }>
                    <Button className={ styles.button } onClick={ () => onClose(currentValue) }>Apply</Button>
                    <Button className={ styles.button } onClick={ () => onClose(value) }>Cancel</Button>
                </section>
            )}
        >
            { tags.map(
                ({ id, name }) => (
                    <Tag
                        key={ id }
                        id={ id }
                        name={ name }
                        selected={ currentValue.includes(id) }
                        onClick={toggle}
                    />
                )
            ) }
        </Popup>
    );
};

export default TagSelector;