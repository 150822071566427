export type Tag = {
    id: number;
    name: string;
};

export type Post = {
    id: number;
    tags: Tag[];
    timestamp: number;
    content:string;
};

export enum HTTP_STATUS {
    OK = 200,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    INTERNAL_ERROR = 500,
};