import React from 'react';

import styles from './Left.module.scss';

const Left: React.FC = ({ children }) => (
    <>
        <section className={ styles.left }>
            { children }
        </section>
    </>
);

export default Left;