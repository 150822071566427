import React from 'react';
import cx from 'classnames';

import styles from './Action.module.scss';

type Props = {
    active?: boolean
    className?: string
    onClick: () => void
    disabled?: boolean
    cancel?: boolean
}

const Action: React.FC<Props> = ({ active, className, onClick, children, disabled, cancel }) => (
    <button
        className={ cx({
            [styles.action]: true,
            [styles.active]: active,
            [styles.cancel]: cancel,
        }, className) }
        onClick={ onClick }
        disabled={ disabled }
    >
        { children }
    </button>
);

export default Action;