import React from 'react';

import styles from './Processing.module.scss';

const Processing: React.FC = ({ children }) => (
    <section className={ styles.processing }>
        <span>{ children }</span>
    </section>
);

export default Processing;